import React, { useState, useEffect } from 'react';
import { TextField, Button, Grid, Autocomplete, Box } from '@mui/material';
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, CircularProgress } from '@mui/material';
//import facilitiesData from './facilities.json';
import axios from 'axios';
//import CountdownProgress from '../components/CountdownProgress';


const ResetPasswordForm = () => {
  const [facilities, setFacilities] = useState([]);
  const [facility, setFacility] = useState(null);
  const [isLoadingFacilities, setIsLoadingFacilities] = useState(false);
  const [facilitiesError, setFacilitiesError] = useState('');
  const [username, setUsername] = useState('');
  //const debouncedUsername = useDebounce(username, 2000); // Adjust debounce time as needed
  const [usernameError, setUsernameError] = useState('');
  const [sessionID, setSessionID] = useState("notSet");
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const baseUrl = process.env.REACT_APP_BACKEND_BASE_URL;
  const code = process.env.REACT_APP_CODE;
  const [password, setPassword] = useState('');
  const [isCopied, setIsCopied] = useState(false);


  const handleSubmit = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior


    // Final validation checks
    if (!facility || !username || usernameError) {
      return;
    }

    setIsCopied(false); // Reset the copied state
    setPassword(''); // Reset the password state
    setIsSubmitting(true); // Disable the submit button
    setDialogContent('Resetting password');
    setIsDialogOpen(true);

    // Send the form data
    try {

      const response = await axios.get(`${baseUrl}PCCPaswordReset-GetData`, {
        params: {
          operation: 'tosubmit',
          orgID: facility.orgID,
          facility: facility.facilityName,
          username: `${facility.orgID}.${username}`
        },
        headers: {
          'sessionID': sessionID,
          'x-functions-key': code
        }
      });

      // Handle the response from the server
      console.log("Form submitted successfully:", response.data);
      setIsSubmitting(false);
      // Handle a successful form submission, e.g., redirecting the user or showing a success message
      // Update session ID from response headers
      const newSessionID = response.headers['sessionid'];
      if (newSessionID && sessionID === "notSet") {
        setSessionID(newSessionID);
        console.log('SessionID', newSessionID)
      }
      // Handle response
      if (response.data.isValid) {
        if (response.data.password) {
          setDialogContent(`Success. Ticket #${response.data.ticketID}. Your temporary password is <b>${response.data.password}</b>`);
          setPassword(response.data.password);
          console.log(`Password reset has been completed and ticket #${response.data.ticketID} was created`)
        } else if (response.data.error) {
          setDialogContent(`ERROR!! ${response.data.error} Please contact support with ticket #${response.data.ticketID}`);
        }
        //cleaning
        setUsername('');
        setSessionID('notSet');
        // Clear any error states
        setUsernameError('');
        


      } else {
        if (response.data.error) {
          setDialogContent(`Error: ${response.data.error}`);
        } else {
          setDialogContent('Unknown server error occured');
        }

        console.log("Data validation failed")
      }
      setIsDialogOpen(true);

    } catch (error) {
      console.error("Failed to submit form:", error);
      // Handle errors from the submission, e.g., showing an error message to the user
      setDialogContent(`Failed to submit form: ${error}`);
      setIsDialogOpen(true);
      setIsSubmitting(false);
    }
  };

  const fetchFacilities = async () => {
    setIsLoadingFacilities(true);
    setFacilitiesError(''); // Reset the error message
    try {
      const response = await axios.get(`${baseUrl}PCCPaswordReset-GetData`, {
        params: {
          operation: 'facility'
        },
        headers: {
          'x-functions-key': code
        }
      });
      const fetchedFacilities = response.data.facilities;
      setFacilities(fetchedFacilities);
      if (fetchedFacilities.length === 1) {
        setFacility(fetchedFacilities[0]);
      }
      // Returned 0 facilities
      if (fetchedFacilities.length === 0) {
        setFacilitiesError('Error loading facilities or IP is not trusted');
      }


    } catch (error) {
      console.error('Failed to fetch facilities:', error);
      setFacilitiesError('Error loading facilities or IP is not trusted');
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setIsLoadingFacilities(false);
    }
  };

  useEffect(() => {
    fetchFacilities();
    // eslint-disable-next-line
  }, []);


  const handleCopyPassword = () => {
    navigator.clipboard.writeText(password).then(() => {
      console.log("Password copied to clipboard!");
      setIsCopied(true);
    });
  };

  return (
    <><Dialog
      open={isDialogOpen}
      onClose={() => setIsDialogOpen(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{"Form Submission Status"}</DialogTitle>
      <DialogContent>
        <Box display="flex" alignItems="center" width="100%">
          {isSubmitting && (
            <Box marginRight={2}>
              <CircularProgress />
            </Box>
          )}
          <div dangerouslySetInnerHTML={{ __html: dialogContent }} />
        </Box>
      </DialogContent>
      <DialogActions>
        {password && (
          <Button onClick={handleCopyPassword} color="primary">
            {isCopied ? 'Copied' : 'Copy Password'}
          </Button>
        )}
        <Button onClick={() => {setPassword(''); setIsDialogOpen(false);}} color="primary" autoFocus disabled={isSubmitting}>
          OK
        </Button>
      </DialogActions>
    </Dialog>

      <form onSubmit={handleSubmit}>

        <Grid container spacing={2}>

          <Grid item xs={12}>
            <Box display="flex" alignItems="center">
              <Autocomplete
                size="small"
                options={facilities}
                getOptionLabel={(option) => `${option.facilityName} (${option.orgID})`}
                renderOption={(props, option) => (
                  <li {...props}>
                    {option.facilityName}{'  '}
                    <span style={{ color: 'gray' }}>
                      ({option.orgID})
                    </span>
                  </li>
                )}
                value={facility}
                onChange={(_event, newValue) => {
                  setFacility(newValue);

                }}
                sx={{ width: '100%' }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Facility"
                    size="small"
                    helperText={facilitiesError || (isLoadingFacilities ? 'Loading facilities...' : 'Select your facility')}
                    error={!!facilitiesError} // Show error style
                  />
                )}
              />

              {isLoadingFacilities && (
                <Box marginLeft={2} marginBottom={2}>
                  <CircularProgress size={30} />
                </Box>
              )}

              {facilitiesError && (
                <Box marginLeft={2} marginBottom={2}>
                  <Button onClick={fetchFacilities} variant="outlined" color="error">
                    Retry
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>

          <Grid item xs={12} container spacing={1} alignItems="flex-end">
            <Grid item>
              <TextField
                disabled
                label="Org ID"
                size="small"
                value={facility ? facility.orgID : ''}
                helperText="Organisation prefix" />
            </Grid>
            <Grid item>
              <Box sx={{ lineHeight: 'normal', fontSize: '24px', paddingTop: '20px' }}>•</Box> {/* Adjust the size and position of the dot */}
            </Grid>
            <Grid item>
              <TextField
                size="small"
                label="Username"
                value={username}
                onChange={(event) => setUsername(event.target.value)}
                //onBlur={checkUsername} // Check username when the field loses focus
                inputProps={{ maxLength: 30 }}
                error={!!usernameError}
                helperText={isCheckingUsername ? 'Checking username...' : (usernameError || 'Enter your username')}
                disabled={!facility} // Disable the field if facility is not selected
              />
            </Grid>
            {isCheckingUsername && (
              <Grid item marginLeft={1} marginBottom={3}>
                <CircularProgress size={30} />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>Submit</Button>
          </Grid>
        </Grid>

      </form></>
  );
};

export default ResetPasswordForm;
