import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Dialog, DialogContent,  DialogActions, CircularProgress, Button, Typography, Box, Container } from '@mui/material';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import axios from 'axios';
import CountdownProgress from '../components/CountdownProgress';


const ResetPasswordPage = () => {

    const { sessionID, authKey } = useParams();
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [resetStatus, setResetStatus] = useState('idle'); 
    const [dialogMessage, setDialogMessage] = useState(''); 
    const [selectedOption, setSelectedOption] = useState('showPassword');
    const [countdownTimer, setCountdownTimer] = useState(120);

    const fetchData = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetData', {
                params: {
                    operation: 'userdata',
                    authkey: authKey
                },
                headers: {
                    'sessionID': sessionID,
                    'x-functions-key': 'FP23tzK3hKDC4kvABTSJ6w6nVpE2tRf-PmUNSCu9c-Y3AzFu_VL6Bw=='
                }
            });
            
            // const response = await axios.get('/data/user.json');
            setUserData(response.data.userdata);
            setResetStatus(response.data.resetStatus);
            if (response.data.resetStatus === 'failed') {
                setDialogMessage(`Password reset failed. Please retry in a few minutes or contact support. Ticket #${response.data.userdata.ticketID}`);
            }
            if (response.data.resetStatus === 'success') {
                selectedOption === 'showPassword' ? 
                    setDialogMessage(`Password reset has been completed. Click <a href="${response.data.pwlink}">this</a> to see the password.`) : 
                    setDialogMessage(`Password reset has been completed. Password has been sent to ${response.data.userdata.phone} using SMS.`);
            }
            if (response.data.resetStatus === 'inProgress') {
                setCountdownTimer(30);
                setDialogMessage('Password reset in progress. Please wait...');
                setTimeout(fetchData, 30000); // Fetch data after 30 seconds
            }
            

        } catch (error) {
            console.error('Error fetching user data:', error);
        } finally {
            setIsLoading(false);
        }   
        
    };
    
    useEffect(() => {
        
        fetchData();
        // eslint-disable-next-line 
    }, []); 

    const handleResetPassword = async () => {
        //setIsLoading(true);
        setResetStatus('inProgress'); // Update reset status
        setDialogMessage('Password reset in progress...'); // Update dialog message
        try {
            const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetData', {
                    params: {
                        operation: 'reset',
                        authkey: authKey,
                        selectedOption: selectedOption
                    },
                    headers: {
                        'sessionID': sessionID,
                        'x-functions-key': 'FP23tzK3hKDC4kvABTSJ6w6nVpE2tRf-PmUNSCu9c-Y3AzFu_VL6Bw=='
                    }
                });

            if (response.data.resetStatus === 'failed') {
                setResetStatus('failed'); // Update reset status
                setDialogMessage(`Password reset failed. Please retry in a few minutes or contact support. Ticket #${response.data.userdata.ticketID}`);
            }

            if(response.data.resetStatus === 'success') {
                setResetStatus('success'); // Update reset status
                console.log(response.data);
                if (selectedOption === 'showPassword') {
                    setDialogMessage(`Password reset has been completed. Click <a href="${response.data.pwlink}">this</a> to see the password.`);
                } else if (selectedOption === 'sendSMS' && response.data.error && response.data.pwlink) {
                    setDialogMessage(`ERROR SENDING SMS!!! Password reset has been completed, however we were unable to deliver password using SMS. Click <a href="${response.data.pwlink}">this</a> to see the password.`);
                } else { 
                    setDialogMessage(`Password reset has been completed. Password has been sent to ${response.data.userdata.phone} using SMS. You can also click <a href="${response.data.pwlink}">this</a> to see the password.`);
                }
                
                
            }

        } catch (error) {
            console.error('Error initiating password reset:', error);
            setResetStatus('failed'); // Update reset status
            setDialogMessage('Connection error. Please retry in a few minutes'); // Update dialog message
        }
        //setIsLoading(false);
    };

    return (
        <Container>
            {isLoading ? (
                <Box display="flex" justifyContent="center" alignItems="top" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box>
                    {userData ? (
                        <Box>
                            <Typography variant="h6">Ticket: {userData.ticketID}</Typography>
                            <Typography variant="h6">Facility: {userData.facility}</Typography>
                            <Typography variant="h6">Username: {userData.username}</Typography>
                            <Typography variant="h6">Full Name: {userData.fullName}</Typography>

                            {userData['phone'] ? (
                                <Box>
                                    <Typography variant="h6">Cell Phone: {userData.phone}</Typography>
                                    <RadioGroup
                                        value={selectedOption}
                                        onChange={(event) => setSelectedOption(event.target.value)}
                                    >
                                        <FormControlLabel value="showPassword" control={<Radio />} label="Reset password and show password here" />
                                        <FormControlLabel value="sendSMS" control={<Radio />} label="Approve user's phone number and send password to this number using SMS" />
                                    </RadioGroup>
                                </Box>
                            ) : null}

                            <Box mt={1}>
                                <Button variant="contained" color="primary" onClick={handleResetPassword} disabled={resetStatus === 'success'}>Reset Password</Button>
                            </Box>
                        </Box>
                    ) : (
                        <Typography variant="h6">No user data available.</Typography>
                    )}
                </Box>
            )}
            <Dialog open={resetStatus !== 'idle'}>
                <DialogContent>
                    <Box m={2} >
                        {resetStatus === 'inProgress' && (
                            <Box display="flex" justifyContent="center" alignItems="center" marginBottom={2}>
                                <CountdownProgress initialCountdown={countdownTimer} />
                            </Box>
                        )}
                        <div dangerouslySetInnerHTML={{ __html: dialogMessage }} />


                    </Box>
                </DialogContent>
                <DialogActions>
                    {resetStatus === 'failed' && <Button color="secondary" onClick={handleResetPassword}>Retry</Button>}
                    <Button color="primary" autoFocus onClick={() => setResetStatus('idle')} disabled={resetStatus !== 'failed'}>Close</Button>
                </DialogActions>

            </Dialog>
        </Container> 
    );
};


export default ResetPasswordPage;