//import React, {useEffect, useState} from 'react';
import { AppBar, Toolbar, Typography, Button, Box, Link as MuiLink } from '@mui/material';
import { Link } from 'react-router-dom';



const CustomAppBar = ({ onLogin, onLogout, isLoggedIn, loggedInUser }) => {

  

  return (
    <AppBar position="static">
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          PCC Password Reset Form
        </Typography>

        <Typography variant="h6" sx={{ flexGrow: 1 }}>
          Computer Associates support line: 718.567.9408
        </Typography>
        
      </Toolbar>
    </AppBar>
  );
};

export default CustomAppBar;
