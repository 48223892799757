import React, { useState } from 'react';
import axios from 'axios';
import { Stepper, Step, StepLabel, Checkbox, FormControlLabel, Grid, Typography, Button, TextField, Table, TablePagination, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { Dialog, DialogContent, DialogActions, Box, CircularProgress } from '@mui/material';


const SearchUserForm = () => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [username, setUsername] = useState('');
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [loadingUsers, setLoadingUsers] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [usersMessage, setUsersMessage] = useState('');
  //supervisors
  const [supervisorFirstName, setSupervisorFirstName] = useState('');
  const [supervisorLastName, setSupervisorLastName] = useState('');
  const [supervisorEmail, setSupervisorEmail] = useState('');
  const [supervisors, setSupervisors] = useState([]);
  const [supervisorPage, setSupervisorPage] = useState(0);
  const [supervisorRowsPerPage, setSupervisorRowsPerPage] = useState(10);
  const [selectedSupervisor, setSelectedSupervisor] = useState(null);
  const [loadingSupervisors, setLoadingSupervisors] = useState(false);
  const [supervisorsMessage, setSupervisorsMessage] = useState('');
  const [emailSupervisor, setEmailSupervisor] = useState(false);

  //stepper
  const [createTicket, setCreateTicket] = useState(false);
  const [ticketToUpdate, setTicketToUpdate] = useState('');

  //dialog
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [dialogMessage, setDialogMessage] = useState('');



  const handleResetPassword = async () => {

    if (!createTicket && ticketToUpdate) {
      if (ticketToUpdate.length < 6) {
        setDialogMessage('Ticket number must be at least 6 characters long');
        setIsDialogOpen(true);
        return;
      }
    }

    setIsSubmitting(true);
    setIsDialogOpen(true);
    setDialogMessage('Resetting password...');
    const supervisorEmail = emailSupervisor ? selectedSupervisor.email : '';
    try {
      const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetDataAuth', {
        params: {
          operation: 'reset',
          username: selectedUser.username,
          orgID: selectedUser.orgID,
          supervisorEmail: supervisorEmail,
          emailSupervisor: emailSupervisor,
          ticketID: ticketToUpdate,
          createTicket: createTicket
        },
        headers: {
          'x-functions-key': 'Lkfxc9TIrSkuCFXa9tYs5JPJbExTAzMx_0I2UIm0z__YAzFu0xVm2A=='
        }
      });
      console.log(response.data); // Handle the response data

      if (response.data.resetStatus === 'success') {
        setDialogMessage(`Password reset was successful ${emailSupervisor ? 'and an email with ' : ''}<a href="${response.data.pwlink}">password link</a> ${emailSupervisor ? 'has been sent to supervisors email ' + supervisorEmail : ''}. Ticket #${response.data.userdata.ticketID} ${createTicket ? 'has been created' : 'has been updated'}`);
        setSelectedUser(null);
        setSelectedSupervisor(null);
        setTicketToUpdate('');
        setCreateTicket(false);
        setFirstName('');
        setLastName('');
        setUsername('');
        setSupervisorFirstName('');
        setSupervisorLastName('');
        setSupervisorEmail('');
        setUsers([]);
        setSupervisors([]);
        setUsersMessage('');
        setSupervisorsMessage('');
        setEmailSupervisor(false);

      } else {
        setDialogMessage(response.data.error);
      }


    } catch (error) {
      console.error('Failed to submit', error);
      setDialogMessage('Network error. Please try again later.');
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setIsSubmitting(false);
    }
  };

  // Add event handlers for the supervisors' pagination
  const handleSupervisorChangePage = (event, newPage) => {
    setSupervisorPage(newPage);
  };

  const handleSupervisorChangeRowsPerPage = (event) => {
    setSupervisorRowsPerPage(parseInt(event.target.value, 10));
    setSupervisorPage(0);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleSearchByName = async () => {

    if (!firstName && !lastName) {
      setUsersMessage('Please enter a first name or last name');
      return;
    }
    setUsers([]); // Clear the users list
    setSelectedUser(null);
    setLoadingUsers(true); // Start loading
    try {
      const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetDataAuth', {
        params: {
          operation: 'userbyname',
          firstName: firstName,
          lastName: lastName
        },
        headers: {
          'x-functions-key': 'Lkfxc9TIrSkuCFXa9tYs5JPJbExTAzMx_0I2UIm0z__YAzFu0xVm2A=='
        }
      });

      if (!response.data.users || (response.data.users && response.data.users.length === 0)) {
        setUsersMessage('No users found');
      } else {
        setUsersMessage('');
        setUsers(response.data.users); // Assuming the response data is an array of users
        if (response.data.users.length === 1) {
          setSelectedUser(response.data.users[0]);
        }
      }
      //console.log(response.data.users);
    } catch (error) {
      console.error('Failed to find', error);
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setLoadingUsers(false); // Stop loading
    }
  };



  const handleSearchByUsername = async () => {
    if (!username) {
      setUsersMessage('Please enter a username');
      return;
    }
    setLoadingUsers(true); // Start loading
    setUsers([]); // Clear the users list
    setSelectedUser(null);
    try {
      const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetDataAuth', {
        params: {
          operation: 'userbyusername',
          username: username
        },
        headers: {
          'x-functions-key': 'Lkfxc9TIrSkuCFXa9tYs5JPJbExTAzMx_0I2UIm0z__YAzFu0xVm2A=='
        }
      });

      if (response.data.users && response.data.users.length === 0) {
        setUsersMessage('No users found');
      } else {
        setUsersMessage('');
        setUsers(response.data.users); // Assuming the response data is an array of users
        if (response.data.users.length === 1) {
          setSelectedUser(response.data.users[0]);
        }
      }

    } catch (error) {
      console.error('Failed to find', error);
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setLoadingUsers(false); // Stop loading
    }
  };


  const handleSearchSupervisorByName = async () => {
    if (!supervisorFirstName && !supervisorLastName) {
      setSupervisorsMessage('Please enter a first name or last name');
      return;
    }
    setLoadingSupervisors(true); // Start loading
    setSupervisors([]); // Clear the supervisors list
    setSelectedSupervisor(null);
    try {
      const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetData', {
        params: {
          operation: 'supervisorbyname',
          orgID: selectedUser.orgID,
          firstName: supervisorFirstName,
          lastName: supervisorLastName
        },
        headers: {
          'x-functions-key': 'FP23tzK3hKDC4kvABTSJ6w6nVpE2tRf-PmUNSCu9c-Y3AzFu_VL6Bw=='
        }
      });

      if (response.data.supervisors && response.data.supervisors.length === 0) {
        setSupervisorsMessage('No supervisors found');
      } else {
        setSupervisorsMessage('');
        setSupervisors(response.data.supervisors); // Assuming the response data is an array of supervisors
        if (response.data.supervisors.length === 1) {
          setSelectedSupervisor(response.data.supervisors[0]);
        }
      }



    } catch (error) {
      console.error('Failed to fetch supervisors:', error);
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setLoadingSupervisors(false); // Stop loading
    }
  };

  const handleSearchSupervisorByEmail = async () => {
    if (!supervisorEmail) {
      setSupervisorsMessage('Please enter an email');
      return;
    }
    setLoadingSupervisors(true); // Start loading
    setSupervisors([]); // Clear the supervisors list
    setSelectedSupervisor(null);
    try {
      const response = await axios.get('https://ndr-pccusermgmt.azurewebsites.net/api/PCCPaswordReset-GetData', {
        params: {
          operation: 'supervisorbyemail',
          orgID: selectedUser.orgID,
          email: supervisorEmail
        },
        headers: {
          'x-functions-key': 'FP23tzK3hKDC4kvABTSJ6w6nVpE2tRf-PmUNSCu9c-Y3AzFu_VL6Bw=='
        }
      });

      if (response.data.supervisors && response.data.supervisors.length === 0) {
        setSupervisorsMessage('No supervisors found');
      } else {
        setSupervisorsMessage('');
        setSupervisors(response.data.supervisors); // Assuming the response data is an array of supervisors
        if (response.data.supervisors.length === 1) {
          setSelectedSupervisor(response.data.supervisors[0]);
        }
      }


    } catch (error) {
      console.error('Failed to fetch supervisors:', error);
      // Handle errors, e.g., set an error state, show a message, etc.
    } finally {
      setLoadingSupervisors(false); // Stop loading
    }
  };


  // Calculate the active step based on whether a user, supervisor, or ticket is selected
  let activeStep = 0;
  if (selectedUser) {
    activeStep = 1;
  }
  if ((selectedSupervisor || !emailSupervisor) && selectedUser) {
    activeStep = 2;
  }
  if ((selectedSupervisor || !emailSupervisor) && selectedUser && (ticketToUpdate || createTicket)) {
    activeStep = 3;
  }

  return (
    <div>

      <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>

        <DialogContent>
          <Box display="flex" alignItems="center" width="100%">
            {isSubmitting && (
              <Box margin={2}>
                <CircularProgress size={24} />
              </Box>
            )}
            <div dangerouslySetInnerHTML={{ __html: dialogMessage }} />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            disabled={isSubmitting}
            onClick={() => setIsDialogOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Stepper activeStep={activeStep} alternativeLabel>
        <Step>
          <StepLabel>Select user</StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={emailSupervisor}
                  onChange={(event) => {
                    setEmailSupervisor(event.target.checked)

                  }
                  }
                />
              }
              label="Email Supervisor"
            />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Grid item xs={2}>
              {!createTicket && (
                <TextField
                  size="small"
                  label="Ticket to Update"
                  value={ticketToUpdate}
                  onChange={(event) => {
                    const onlyNums = event.target.value.replace(/[^0-9]/g, '');
                    setTicketToUpdate(onlyNums);
                  }}
                  inputProps={{ pattern: "\\d*", maxLength: 9 }}
                  fullWidth
                />
              )}
            </Grid>
            <FormControlLabel
              control={
                <Checkbox
                  checked={createTicket}
                  onChange={(event) => {
                    setCreateTicket(event.target.checked)
                    if (event.target.checked) {
                      setTicketToUpdate('');
                    }
                  }
                  }
                />
              }
              label="Create new ticket"
            />
          </StepLabel>
        </Step>
        <Step>
          <StepLabel>
            <Button
              disabled={!((selectedSupervisor || !emailSupervisor) && selectedUser && (ticketToUpdate || createTicket))}
              variant="contained"
              color="primary"
              onClick={handleResetPassword}>
              Reset Password
            </Button>
          </StepLabel>
        </Step>
      </Stepper>


      <Typography variant="h6">User search: </Typography>

      <Grid container spacing={1} alignItems="center" marginBottom={1}>
        <Grid item xs={2}>
          <TextField
            size="small"
            label="First Name"
            value={firstName}
            onChange={e => setFirstName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <TextField
            size="small"
            label="Last Name"
            value={lastName}
            onChange={e => setLastName(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={2}>
          <Button variant="contained" color="primary" onClick={handleSearchByName}>Search by Name</Button>
        </Grid>
        <Grid item xs={3}>
          <TextField
            size="small"
            label="Username"
            value={username}
            onChange={e => setUsername(e.target.value)}
            fullWidth
          />
        </Grid>
        <Grid item xs={3}>
          <Button variant="contained" color="primary" onClick={handleSearchByUsername}>Search by Username</Button>
        </Grid>
      </Grid>





      <TableContainer component={Paper}>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell style={{ fontWeight: 'bold' }}>Name</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Username</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Company</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Site</TableCell>
              <TableCell style={{ fontWeight: 'bold' }}>Multi-Facility</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((user) => (
              <TableRow
                key={user.username}
                onClick={() => setSelectedUser(user)}
                style={{
                  backgroundColor: selectedUser?.id === user.id ? '#ddd' : null
                }}
              >
                <TableCell>{user.firstName} {user.lastName}</TableCell>
                <TableCell>{user.username}</TableCell>
                <TableCell>{user.company}</TableCell>
                <TableCell>{user.site}</TableCell>
                <TableCell>{user.multifacility ? 'Yes' : 'No'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {users.length > 0 && (
          <TablePagination
            component="div"
            count={users.length}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>

      <Grid item xs={4} marginTop={2}>
        {loadingUsers && <CircularProgress size={30} />}
        {!loadingUsers && usersMessage && <Typography variant="body1">{usersMessage}</Typography>}
      </Grid>

      {emailSupervisor && (
        <div>
          <Typography variant="h6" marginTop={3}>Supervisor search: </Typography>

          <Grid container spacing={2} alignItems="center">
            <Grid item xs={2}>
              <TextField
                label="First Name"
                value={supervisorFirstName}
                onChange={e => setSupervisorFirstName(e.target.value)}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Last Name"
                value={supervisorLastName}
                onChange={e => setSupervisorLastName(e.target.value)}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={2}>
              <Button disabled={!selectedUser} variant="contained" color="primary" onClick={handleSearchSupervisorByName}>Search by Name</Button>
            </Grid>

            <Grid item xs={3}>
              <TextField
                label="Email"
                value={supervisorEmail}
                onChange={e => setSupervisorEmail(e.target.value)}
                fullWidth
                size='small'
              />
            </Grid>
            <Grid item xs={3}>
              <Button disabled={!selectedUser} variant="contained" color="primary" onClick={handleSearchSupervisorByEmail}>Search by Email</Button>
            </Grid>
          </Grid>

          <TableContainer component={Paper}>
            <Table size='small'>
              <TableHead>
                <TableRow>
                  <TableCell style={{ fontWeight: 'bold' }}>Full Name</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Position</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Email</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Company</TableCell>
                  <TableCell style={{ fontWeight: 'bold' }}>Site</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {supervisors.slice(supervisorPage * supervisorRowsPerPage, supervisorPage * supervisorRowsPerPage + supervisorRowsPerPage).map((supervisor) => (
                  <TableRow
                    key={supervisor.email}
                    onClick={() => setSelectedSupervisor(supervisor)}
                    style={{
                      backgroundColor: selectedSupervisor?.email === supervisor.email ? '#ddd' : null
                    }}
                  >
                    <TableCell>{supervisor.name} </TableCell>
                    <TableCell>{supervisor.position}</TableCell>
                    <TableCell>{supervisor.email}</TableCell>
                    <TableCell>{supervisor.company}</TableCell>
                    <TableCell>{supervisor.site}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
            {supervisors.length > 0 && (
              <TablePagination
                component="div"
                count={supervisors.length}
                page={supervisorPage}
                onPageChange={handleSupervisorChangePage}
                rowsPerPage={supervisorRowsPerPage}
                onRowsPerPageChange={handleSupervisorChangeRowsPerPage}
              />
            )}
          </TableContainer>

          <Grid item xs={4} marginTop={2}>
            {loadingSupervisors && <CircularProgress size={30} />}
            {!loadingSupervisors && supervisorsMessage && <Typography variant="body1">{supervisorsMessage}</Typography>}
          </Grid>
        </div>
      )}

    </div>
  );
};

export default SearchUserForm;