import React, { useState, useEffect } from 'react';
import CustomAppBar from './CustomAppBar';
import ResetPasswordForm from './pages/ResetPasswordForm';
import ResetPasswordPage from './pages/ResetPasswordPage';
import SearchUserForm from './pages/SearchUserForm';
import { Container, Box } from '@mui/material';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthCallback from './pages/AuthCallback';
// MSAL imports
import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from './authConfig';


const App = ({ pca }) => {
  const [isLoggedIn, setIsLoggedIn] = useState(useIsAuthenticated());
  const [loggedInUser, setLoggedInUser] = useState('');

  useEffect(() => {
    const account = pca.getActiveAccount();
    console.log('Active account:', account);
    if (account) {
      setIsLoggedIn(true);
      setLoggedInUser(account.name);
    }
  }, [pca]);

  const handleLogin = async () => {
    try {
      await pca.loginPopup(loginRequest);
      const account = pca.getActiveAccount();
      setLoggedInUser(account.name);
      setIsLoggedIn(true);
    } catch (error) {
      console.error('Login failed:', error);
    }
  };

  const handleLogout = () => {
    pca.logoutPopup();
    setIsLoggedIn(false);
  };

  return (
    <MsalProvider instance={pca}>
    <Router>
      <Box sx={{ flexGrow: 1 }}>
        <CustomAppBar onLogin={handleLogin} onLogout={handleLogout} isLoggedIn={isLoggedIn} loggedInUser={loggedInUser} />
        <Container sx={{ marginTop: 8 }}>
          <Routes>
            <Route path="/" element={<ResetPasswordForm />} />
            <Route path="/reset-password" element={<ResetPasswordForm />} />
            <Route path="/resetpw/:sessionID/:authKey" element={<ResetPasswordPage />} />
            <Route path="/auth/callback" element={<AuthCallback />} />
            <Route path="/onboard-user" element={isLoggedIn ? <div>Onboard User Form</div> : <Navigate to="/" />} />
            <Route path="/offboard-user" element={isLoggedIn ? <div>Offboard User Form</div> : <Navigate to="/" />} />
            <Route path="/search-user" element={isLoggedIn ? <SearchUserForm /> : <Navigate to="/" />} />
          </Routes>
        </Container>
      </Box>
    </Router>
    </MsalProvider>
  );
};

export default App;
