import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { msalConfig } from '../authConfig';
import { PublicClientApplication } from '@azure/msal-browser';


const msalInstance = new PublicClientApplication(msalConfig);
await msalInstance.initialize();

const AuthCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {


    msalInstance.handleRedirectPromise()
      .then(response => {
        // Assuming you want to navigate to the home page if login is successful
        navigate('/');
      })
      .catch(error => {
        // Handle or log error, and redirect user to a safe place
        console.error(error);
        navigate('/');
      });
  }, [navigate]);

  return <CircularProgress />;
};

export default AuthCallback;
