export const msalConfig = {
    auth: {
      clientId: '36d47791-7f35-41f7-9473-4d7a42c5d1f7', // Replace with your Azure AD client ID
      authority: 'https://login.microsoftonline.com/b85077ce-59ca-4a5c-a0be-a7a610623572', // Replace with your Azure AD tenant ID
      //redirectUri: process.env.REACT_APP_REDIRECT_URI, // Replace with your redirect URI
      redirectUri: '/'
    },
  };
  
  export const loginRequest = {
    scopes: ['User.Read'], // Add more scopes as needed
  };
  