import React, { useEffect,useState } from 'react';
import { CircularProgress, Box, Typography } from '@mui/material';

const CountdownProgress = ({ initialCountdown }) => {
    const [countdown, setCountdown] = useState(initialCountdown);

    useEffect(() => {
        if (countdown > 0) {
            const timer = setTimeout(() => {
                setCountdown(countdown - 1);
            }, 1000);
            return () => clearTimeout(timer);
        } else {
            window.location.reload();
        }
    }, [countdown]);

    return (
        <Box position="relative" display="inline-flex">
            <CircularProgress variant="determinate" value={(countdown / initialCountdown) * 100} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" color="textSecondary">{`${countdown}s`}</Typography>
            </Box>
        </Box>
    );
}

export default CountdownProgress;